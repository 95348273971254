<template>
  <div class="compare-wrap">
    <faceForm @getFormParams="getFormParams"></faceForm>
    <div class="section statistics-wrap">
      <empty :show="!chart.count.length" text="暂无统计数据"></empty>
      <ul class="list" v-show="chart.count.length">
        <li class="item" v-for="item in chart.count" :class="{'desc': item.change === 2, 'asc': item.change === 3}">
          <div class="value"><countTo :startVal="0" :endVal="item.amount" :decimals="2" :duration="1500"></countTo></div>
          <div class="label">{{item.name}}（{{item.unit}}）</div>
        </li>
      </ul>
    </div>
    <section class="section charts-wrap">
      <div class="title">华宏汽车集团门店同环比</div>
      <div class="tab-wrap"><tab :tabs="tabs" @handleTabClick="handleTabClick"></tab></div>
      <ve-histogram width="100%" height="300px" :data="chart.data" :extend="chart.extend" :dataZoom="chart.dataZoom"></ve-histogram>
    </section>
  </div>
</template>

<script>
  import faceForm from "@/views/face/form"
  import countTo from "vue-count-to"
  import VeHistogram from 'v-charts/lib/histogram'
  import "echarts/lib/component/dataZoom"
  import tab from "@/components/tab"
  import empty from "@/components/empty"
  import Api from "@/views/face/api"

  export default {
    name: "compare",
    components: {
      tab,
      empty,
      VeHistogram,
      faceForm,
      countTo
    },
    data: () => ({
      tabs: [
        {label: '进客流', value: 'in'},
        {label: '出客流', value: 'out'},
        {label: '集客流', value: 'per'},
      ],
      chart: {
        query: {
          store: '',
          period: '',
          gender: '',
          age_type: '',
          start_date_time: '',
          end_date_time: '',
          stat_category: 'in',
        },
        data: {
          columns: ['时间'],
          rows: [],
        },
        count: [],
        extend: {
          legend: {formatter: name => name.slice(0, 2)},
        },
        dataZoom: [{
          type: 'slider',
          show: true
        }, {type: 'inside'}],
      },
      view: 'table',
      table: {
        data: []
      }
    }),
    methods: {
      getFormParams(params) {
        Object.keys(params).forEach(key => this.chart.query[key] = params[key])
        this.getStatistics()
      },
      handleTabClick(data) {
        this.chart.query.stat_category = data
        this.getStatistics()
      },
      getStatistics() {
        Api.compare(this.chart.query).then(res => {
          if (res.data) {
            const {rows, xValue} = res.data
            this.chart.data.columns = []
            this.chart.count = []
            rows.forEach(item => {
              this.chart.data.columns.push(item.name)
              this.chart.count.push({change: item.change, name: item.name, amount: item.total.amount.replaceAll(',', '') * 1, unit: item.total.unit})
            })
            this.chart.data.rows = xValue.map((time, i) => Object.assign({['时间']: time}, ...rows.map(row => ({[row.name]: row.yList[i]}))))
          } else {
            this.chart.data.columns = []
            this.chart.count = []
            this.chart.data.rows = []
          }
        }).catch(err => {
          console.log(err);
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .statistics-wrap {
    padding: 0;
    background-color: #FFF;
    .list {
      padding: 0;
      .item {
        position: relative;
        padding: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
        box-sizing: border-box;
        border-bottom: 1px solid #EEE;
        &.desc {
          .value {
            color: green;
            &:before {
              display: block;
              border-bottom: 6px solid green;
            }
          }
        }
        &.asc {
          .value {
            color: red;
            &:before {
              display: block;
              border-top: 6px solid red;
            }
          }
        }
        &:last-of-type {border-bottom: none;}
        .label {
          color: #AAA;
          font-size: 14px;
          margin-top: 10px;
        }
        .value {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          font-size: 24px;
          &:before {
            display: none;
            content: '';
            width: 0;
            height: 0;
            line-height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .charts-wrap {
    .title {
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .tab-wrap {
      text-align: center;
      margin-bottom: 15px;
    }
  }
</style>