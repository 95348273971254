<template>
  <div class="empty-wrap" v-show="show" :class="{'center': center}">
    <i class="icon" :class="icon"></i>
    <div class="text">{{text}}</div>
  </div>
</template>

<script>
  export default {
    name: "empty",
    props: {
      icon: {type: String, default: 'el-icon-receiving'},
      text: {type: String, default: '暂无数据'},
      center: {type: Boolean, default: false},
      show: {type: Boolean, default: false}
    }
  }
</script>

<style scoped lang="scss">
  .empty-wrap {
    padding: 20px;
    text-align: center;
    &.center {
      @include position($l: 50%, $t: 50%);
      transform: translate(-50%, -50%);
    }
    .icon {
      color: #BBB;
      font-size: 40px;
    }
    .text {
      color: #BBB;
      font-size: 14px;
      margin-top: 5px;
    }
  }
</style>